<template>
  <StoryblokComponent
    v-for="stageBlok in stageBlocks"
    :key="stageBlok._uid"
    :blok="stageBlok"
    class="mx-auto max-w-7xl"
    :preload-media="true"
  />
  <div
    class="mx-auto py-4"
    :class="{
      'container max-w-6xl': !fullWidth,
      'w-full': fullWidth,
    }"
  >
    <div v-if="contentBlocks?.length">
      <div v-for="blok in contentBlocks" :key="blok._uid" class="mx-auto">
        <AsyncStoryblokComponent
          :blok="blok"
          class="mx-auto max-w-7xl"
          :preload-media="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SbBlokData } from '@storyblok/vue'
import type { SbPage } from '~/storyblok/types/storyblok'
import { isPrivacyPage } from '~/utils/route'

const route = useRoute()
const { configuredLocale, isMultilanguageShop, redirectToLocalePage } =
  useLocale()
const { isInEditorMode } = useStoryblokHelpers()
const { isStoreMode } = useFeatureToggles()
const $currentShop = useCurrentShop()
const scope = effectScope()
const { logger } = useLogging()
onScopeDispose(() => scope.stop())

const { data } = await useCmsStory<SbPage>({
  params: {
    slug: route.path,
    baseFolder: CmsBaseFolder.Content,
  },
})

scope?.run(() => {
  if (import.meta.server) {
    return
  }
  watch(
    configuredLocale,
    async (newLang) => {
      if (
        !isMultilanguageShop.value ||
        isInEditorMode.value ||
        $currentShop.value?.locale === newLang
      ) {
        return
      }

      const translatedSlug = (data.value?.translated_slugs ?? []).find(
        (slug) => slug.lang === (newLang ?? '').toLowerCase(),
      )
      if (!translatedSlug) {
        logger.error(
          `Could not find storyblok translatedSlug for: ${(
            newLang ?? ''
          ).toLowerCase()}`,
          {
            why: 'language-switch',
            where: 'ContentPage.vue',
            locale: newLang,
          },
        )

        return
      }
      const path = `${getRedirectToFromTranslatedSlug(translatedSlug)}/`
      const query = route.fullPath.split('?')[1]

      await redirectToLocalePage(path, query)
    },
    { immediate: true },
  )
})

const contentBlocks = computed(() => data.value?.content?.content ?? [])
const stageBlocks = computed(
  () => (data.value?.content?.stage ?? []) as SbBlokData[],
)

onBeforeMount(() => {
  if (isPrivacyPage(data.value?.slug) || isStoreMode.value) {
    // Cookie Consent Popup should not be shown on Datenschutz page and store mode
    document.documentElement?.classList.add('hide-cookie-consent')
  }
})

onBeforeUnmount(() => {
  document.documentElement?.classList.remove('hide-cookie-consent')
})

const fullWidth = computed(() => !!data.value?.content?.full_width)

const cmsMeta = computed(() => getCmsMeta(data.value?.content))
useSeoMeta(cmsMeta.value)
useHead({
  link: [
    {
      rel: 'canonical',
      key: 'canonical',
      href: sanitizeCanonical(getUrl($currentShop.value.domain, route?.path)),
    },
  ],
})
</script>
